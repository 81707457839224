import React, { useState } from 'react'
import "./styles/main.css";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import * as apiurls from '../../api/apiurls'

const CreateBusiness = () => {
  const [businessName,setBusinessName]= useState("");
  const [businessWPNumber,setBusinessWPNumber]= useState("");
  const [businessContactNumber,setBusinessContactNumber]= useState("");
  const [businessContactMail,setBusinessContactMail]= useState("");
  const [businessAdminUsername,setBusinessAdminUsername]= useState("");
  const [businessAdminName,setBusinessAdminName]= useState("");
  const [businessAdminSurname,setBusinessAdminSurname]= useState("");
  const [businessAdminMail,setBusinessAdminMail]= useState("");
  const [businessAdminPassword,setBusinessAdminPassword]= useState("");
	
  const submitForm=(event)=>{
    event.preventDefault();
    let business= {
      business:{
        name:businessName,
        phone_number:businessWPNumber,
        contact:{
          phone_number:businessContactNumber,
          mail:businessContactMail
        }
      },
      user:{
        username:businessAdminUsername,
        name:businessAdminName,
        surname:businessAdminSurname,
        mail:businessAdminMail,
        password:businessAdminPassword
      }
    }
    console.log(business);
    axios.post(apiurls.CREATE_BUSINESS,business).then(data=>{
      if(!data.data.error){
        NotificationManager.success('İşletme hesabı başarı ile oluşturuldu.', 'Başarılı', 5000, null); 
      }
      ClearForm();
    }).catch(err=>{
      console.log(err);
        NotificationManager.error(err.response.data?.message, 'Hata', 5000, null); 
    })
  }

  const ClearForm=()=>{
    setBusinessName("");
    setBusinessWPNumber("");
    setBusinessContactMail("");
    setBusinessContactNumber("");
    setBusinessAdminName("");
    setBusinessAdminSurname("");
    setBusinessAdminMail("");
    setBusinessAdminPassword("");
    setBusinessAdminUsername("");
  }

  return (
    <div className='createbusiness'>
    <form onSubmit={submitForm} >
      <div className="business_info">
        <label htmlFor="">BUSINESS INFO</label>
      </div>
      <div className="cb__input-wrapper">
        <input type='text' className="chat__input" value={businessName} onChange={(e) => { setBusinessName(e.target.value); }} placeholder='Name' required />
      </div>
      <div className="cb__input-wrapper">
        <input type='text' className="chat__input" value={businessWPNumber} placeholder='Whatsapp Number' required onChange={(e) => { setBusinessWPNumber(e.target.value); }} />
      </div> <br />
      <div className="business_info">
        <label htmlFor="">BUSINESS CONTACT INFO</label>
      </div>
      <div className="cb__input-wrapper">
        <input type='text' className="chat__input" value={businessContactNumber} placeholder='Phone Number' required onChange={(e) => { setBusinessContactNumber(e.target.value); }} />
      </div>
      <div className="cb__input-wrapper">
        <input type='email' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="chat__input" value={businessContactMail} placeholder='Mail Address' required onChange={(e) => { setBusinessContactMail(e.target.value); }} />
      </div> <br />
      <div className="business_info">
        <label htmlFor="">BUSINESS ADMIN INFO</label>
      </div>
      <div className="cb__input-wrapper">
        <input type='text' className="chat__input" value={businessAdminUsername} placeholder='Username' required onChange={(e) => { setBusinessAdminUsername(e.target.value); }} />
      </div>
      <div className="cb__input-wrapper">
        <input type='text' className="chat__input" value={businessAdminName} placeholder='Name' required onChange={(e) => { setBusinessAdminName(e.target.value); }} />
      </div>
      <div className="cb__input-wrapper">
        <input type='text' className="chat__input" value={businessAdminSurname} placeholder='Surname' required onChange={(e) => { setBusinessAdminSurname(e.target.value); }} />
      </div>
      <div className="cb__input-wrapper">
        <input type='email' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="chat__input" value={businessAdminMail} placeholder='Mail Address' required onChange={(e) => { setBusinessAdminMail(e.target.value); }} />
      </div>
      <div className="cb__input-wrapper">
        <input type='password' className="chat__input" value={businessAdminPassword} placeholder='Password' required onChange={(e) => { setBusinessAdminPassword(e.target.value); }} />
      </div> <br />
      <div className="createbusiness__button-wrapper">
      <button className='createbusiness_button' type='submit' >CREATE BUSINESS ACCOUNT</button>
      </div>
    </form>
    </div>
  )
}

export default CreateBusiness;