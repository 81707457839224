import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useAuthContext } from "./authContext";
import * as apiurls from '../api/apiurls'



const SOCKET_URL = apiurls.SOCKET_URL;

const SocketContext = createContext();
const useSocketContext = () => useContext(SocketContext);

const SocketProvider = ({ children }) => {
	const { token } = useAuthContext();
	const [socket, setSocket] = useState(null);
	const [wpAccountStatus, setWpAccountStatus] = useState({client_status:false,message:'',qrcode:null});

	useEffect(() => {
		if (token) {
			let sckt = io(SOCKET_URL, {
				extraHeaders: {
					Authorization: "Bearer "+token
				}
			});

			sckt.connect();
			sckt.on('client_status', (status) =>{
				setWpAccountStatus(status)
			})
			setSocket(sckt);
		}
		else{
			if(socket!==null)
			{	
				socket.disconnect();
			}
			setWpAccountStatus({client_status:false,message:'',qrcode:null});
		}
		// eslint-disable-next-line
	}, [token])


	return (
		<SocketContext.Provider value={{socket,wpAccountStatus}}>{children}</SocketContext.Provider>
	);
};

export { useSocketContext, SocketProvider };
