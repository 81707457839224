import React, { useEffect, useState } from "react";
import "./App.css";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Loader from "./components/Loader";
import Home from "./pages/Home";
import Sidebar from "components/Sidebar";
import CreateBusiness from "./pages/CreateBusiness";
import ChangePassword from "./pages/ChangePassword";
import SupportHistory from "./pages/SupportHistory";
import EmployeeStats from "./pages/EmployeeStats";
import SupportChat from "pages/SupportChat";
import CreateApiKey from "pages/CreateApiKey";
import Categories from "pages/Categories";
import Users from "pages/Users";
import Login from "pages/Login";
import AuthWhatsApp from "pages/AuthWhatsApp";
import { useAuthContext } from "context/authContext";
import { useSocketContext } from "context/socketContext";
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


const userPrefersDark =
	window.matchMedia &&
	window.matchMedia("(prefers-color-scheme: dark)").matches;

function App() {
	const [appLoaded, setAppLoaded] = useState(false);
	const [startLoadProgress, setStartLoadProgress] = useState(false);
	const { token,authenticatedUser } = useAuthContext();
	const { wpAccountStatus } = useSocketContext();

	useEffect(() => {
		if (true) document.body.classList.add("dark-theme");
		stopLoad();
	}, []);

	useEffect(() => {
	  console.log(authenticatedUser);
	}, [authenticatedUser])
	

	const stopLoad = () => {
		setStartLoadProgress(true);
		setTimeout(() => setAppLoaded(true), 0);
	};

	if (!appLoaded) return <Loader done={startLoadProgress} />;

	return (
		<div className="app">
			<p className="app__mobile-message"> Only available on desktop 😊. </p>
			{token && wpAccountStatus.client_status ?
				<Router>
					<div className="app-content">
						<Sidebar />
						<Switch>
							<Redirect exact from={`/`} to={`/home`} />
							<Route path="/supportchat/:id" component={SupportChat} />
							<Route path="/supportchat/" component={Home} />
							<Route path="/home/" component={Home} />
							<Route path="/supporthistory/" component={SupportHistory} />
							<Route path="/changepassword/" component={ChangePassword} />
							{authenticatedUser?.is_business_admin?<Route path="/categories" component={Categories} />:null}
							{authenticatedUser?.is_business_admin?<Route path="/users" component={Users} />:null}
							{authenticatedUser?.is_business_admin?<Route path="/employeestats" component={EmployeeStats} />:null}
							{authenticatedUser?.is_business_admin?<Route path="/createapikey/" component={CreateApiKey} />:null}
							<Redirect to={`/home`} />
						</Switch>
					</div>
				</Router>
				: token && !wpAccountStatus.client_status ? <Router>
					<div className="app-content">
						<Switch>
							<Redirect exact from={`/`} to={`/authwhatsapp`} />
							<Route path="/authwhatsapp" component={AuthWhatsApp} />
							<Route path="/supportchat/:id" component={AuthWhatsApp} />
							<Route path="/supportchat/" component={AuthWhatsApp} />
							<Route path="/home/" component={AuthWhatsApp} />
							<Redirect to={`/authwhatsapp`} />
						</Switch>
					</div>
				</Router> :
					<Router>
						<div className="app-content">
							<Switch>
								<Redirect exact from={`/`} to={`/login`} />
								<Route path="/login" component={Login} />
								<Route path="/createbusiness" component={CreateBusiness} />
								<Redirect to={`/login`} />
							</Switch>
						</div>
					</Router>
			}
        <NotificationContainer/>

		</div>
	);
}

export default App;
