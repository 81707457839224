import React from "react";
import OptionsBtn from "components/OptionsButton";


const Header = ({ user,closeSupport }) => {
	return (
		<header className="header chat__header">
			<div className="chat__contact-wrapper">
				<h2 className="chat__contact-name"> {user?.user_name} - {user?.category_name}</h2>
			</div>
			<div className="chat__actions">
				{/* {!user?.is_closed ? <button
					className="chat__action_close_support"
					aria-label="Sohbeti Sonlandır"
					onClick={closeSupport}
				>
					Sohbeti Sonlandır
				</button> :null} */}
				{!user?.is_closed ? 
					<OptionsBtn
						className="chat__action_close_support" 
						text="Sohbeti Sonlandır" 
						aria-label="Sohbeti Sonlandır"
						onOptionSelect={closeSupport}
						options={
							user?.options_to_close_support
						} >
							Sohbeti Sonlandır
				</OptionsBtn> :user?.status}
			</div>
		</header>
	);
};

export default Header;
