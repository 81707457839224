import React, { useState, useEffect } from 'react'
import "./styles/main.css";
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Pagination from '@mui/material/Pagination';
import { useUsersContext } from "context/usersContext";
import axios from 'axios';
import * as apiurls from '../../api/apiurls'
import Icon from "components/Icon";
import { useHistory } from 'react-router-dom';




const SupportHistory = () => {
  const { header, supportCategories, employees, authenticatedUser } = useUsersContext();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [perpage, setPerPage] = useState(25);
  const [rows, setRows] = useState([]);

  const [search, setSearch] = useState("");
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const history = useHistory();

  useEffect(() => {
    GetHistory();
  }, [page])

  const ClearFilter=()=>{
    setSearch("");
    setStartDate("");
    setEndDate("");
    setSelectedCategory("");
    setSelectedEmployee("");
  }
  const GetHistory = () => {
    let body = {
      start_date: startdate,
      end_date: enddate,
      category_id: selectedCategory,
      employee_id: selectedEmployee,
      search: search,
    }

    axios.post(apiurls.SUPPORT_HISTORY, body, { ...header, params: { page: page } }).then(response => {
      let data = response.data.results;
      setPage(data.page);
      setPageCount(data.page_count);
      setPerPage(data.perpage);
      console.log(data.history);
      data.history.map(h => {
        let cat = supportCategories.filter(c => c.category_id === h.category_id);
        if (cat.length > 0) {
          h.category_name = cat[0].category;
        }
        h.id = h.support_id;
        h.created_date = new Date(h.created_date);
        h.assigned_date = new Date(h.assigned_date);
        h.closed_date = new Date(h.closed_date);
      });
      setRows(data.history);
    }).catch(err => { });
  }

  const RowOnClick = (e) => {
    history.push('/supportchat/' + e.id);
  }


  const CustomPagination = () => {
    return (
      <Pagination
        count={pageCount}
        page={page}
        onChange={(event, value) => setPage(value)}
      />
    );
  }

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'user_name', headerName: 'Destek Alan', width: 150 },
    { field: 'category_name', headerName: 'Kategori', width: 130 },
    { field: 'employee', headerName: 'Destek Veren', width: 150 },
    // { field: 'created_date', headerName: 'Oluşturulma', type: 'dateTime', width: 175, },
    { field: 'assigned_date', headerName: 'Çalışan Bağlanma', type: 'dateTime', width: 175, },
    { field: 'closed_date', headerName: 'Kapatma', type: 'dateTime', width: 175, },
    { field: 'status', headerName: 'Durum', width: 175 },
  ];

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <div className='sh'>
      <div className='sh__filter-wrapper'>
      <button
          className="sh__clear-filter-btn"
          aria-label="clear"
          onClick={ClearFilter}
        >
          <Icon id="delete" />
        </button>
        <div className='sh__date-picker-wrapper' >
          <label className='sh__date-picker-label' >Başlangıç</label>
          <input type="date" className='sh__date-picker' value={startdate} onChange={(e) => { setStartDate(e.target.value); }} ></input>
        </div>
        <div className='sh__date-picker-wrapper' >
          <label className='sh__date-picker-label' >Bitiş</label>
          <input type="date" className='sh__date-picker' value={enddate} onChange={(e) => { setEndDate(e.target.value); }} ></input>
        </div>
        <div className='sh__search-wrapper' >
          <div className='sh__search-icons' >
            <Icon id="search" className="search-icon" />
          </div>
          <input className='sh__search' placeholder='Search' value={search} onChange={(e) => { setSearch(e.target.value); }} ></input>
        </div>
        <div className='sh__select-wrapper' >
          <select className='sh__select'  onChange={(e) => { setSelectedCategory(e.target.value); }}>
            <option value={""} selected={selectedCategory===""} >Tüm Kategoriler</option>
            {
              supportCategories?.map((cate, index) => <option key={index} value={cate.category_id}>{cate.category}</option>)
            }
          </select>
        </div>
        <div className='sh__select-wrapper' >
          {authenticatedUser?.is_business_admin ? <select className='sh__select' onChange={(e) => { setSelectedEmployee(e.target.value); }} >
            <option value={""} selected={selectedCategory===""} >Tüm Çalışanlar</option>
            {
              employees?.map((emp, index) => <option key={index} value={emp.id}>{emp.name} {emp.surname}</option>)
            }
          </select> : null}
        </div>
        {/* <button onClick={GetHistory} style={{ color: 'white' }} >ARA</button> */}
        <button
          className="sh__find-btn"
          aria-label="find"
          onClick={GetHistory}
        >
          <Icon id="search" />
        </button>
      </div>
      <div className='sh__table-wrapper'>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <DataGrid
            hideFooterSelectedRowCount={true}
            disableColumnMenu={true}
            pagination={true}
            rows={rows}
            showColumnRightBorder={true}
            columns={columns}
            pageSize={perpage}
            onRowDoubleClick={RowOnClick}
            components={{
              Pagination: CustomPagination,
            }}

          />
        </ThemeProvider>

      </div>
    </div>
  )
}

export default SupportHistory;