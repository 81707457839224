import React, { useState, useEffect } from 'react'
import "./styles/main.css";
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useUsersContext } from "context/usersContext";
import axios from 'axios';
import * as apiurls from '../../api/apiurls'
import { Chart } from "react-google-charts";



const EmployeeStats = () => {
  const { header } = useUsersContext();
  const [stats, setStats] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);

  useEffect(() => {
    let curr_date = new Date();
    let _year = curr_date.getUTCFullYear();
    let _month = curr_date.getMonth() + 1;
    setMonth(_month);
    setYear(_year);
  }, [])

  useEffect(() => {
    if (year && month) {
      GetData(year, month);
    }
  }, [month, year])


  const GetData = (_year, _month) => {
    axios.post(apiurls.EMPLOYEE_STATS, { year: year, month: month }, header).then((response) => {
      setStats(response.data.results);
      let data = [["Çalışan", "Kapanmış Destek"]];
      response.data.results.map((d) => {
        console.log(d);
        data.push([d.user_name + " " + d.user_surname, d.closed_support_count]);
      });
      setChartData(data);
    }).catch((err) => {

    });
  }


  const RowOnClick = (e) => {

  }

  const chart_options = {
    hAxis: {
      textStyle: { color: 'white' }
    },
    vAxis: {
      textStyle: { color: 'white' }
    },
    legend: { textStyle: { color: 'white' } },
    backgroundColor: 'transparent',
    colors: ['#056162'],
    width: '100%',
    height: '100%',
  };


  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'user_name', headerName: 'Çalışan', minWidth: 150, flex: 0.3 },
    { field: 'closed_support_count', headerName: 'Kapanmış Destek', minWidth: 170, flex: 0.2 },
    { field: 'active_support_count', headerName: 'Aktif Destek', minWidth: 170, flex: 0.2 },
    { field: 'average_support_time_in_minute', headerName: 'Ortalama Sorun Çözme Süresi (dk)', minWidth: 170, flex: 0.3 },

  ];

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const OnDateChanged = (e) => {
    let val = e.target.value;
    if (val) {
      let splited = val.split('-');
      setMonth(Number(splited[1]));
      setYear(Number(splited[0]));
    }
  }

  const formatMonth = (year, month) => {
    if (month && year) {
      if (month.toString().length === 1) {
        return year + "-0" + month;
      }
      else {
        return year + "-" + month;
      }
    }
    else{
      return "";
    }
  }

  return (
    <div className='es'>
      {/* <div className='es__filter-wrapperr'>
        <div className='es__month-picker-wrapper' >
          <input type="month" className='es__month-picker' />
        </div>
      </div> */}
      <div className='es__chart-wrapper'>
        <div className='es__month-picker-wrapper' >
          <input type="month" value={formatMonth(year, month)} onChange={OnDateChanged} className='es__month-picker' />
        </div>
        <Chart

          className='es__chart'
          chartType="ColumnChart"
          data={chartData}
          options={chart_options}
        />
      </div>
      <div className='es__table-wrapper'>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <DataGrid
            disableColumnMenu={true}
            hideFooter={true}
            rows={stats}
            showColumnRightBorder={true}
            columns={columns}
            onRowDoubleClick={RowOnClick}
          />
        </ThemeProvider>

      </div>
    </div>
  )
}

export default EmployeeStats;