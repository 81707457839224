import React, { useState } from 'react'
import "./styles/main.css";
import { NotificationManager } from 'react-notifications';
import { useUsersContext } from "context/usersContext";



const ChangePassword = () => {
	const { changePassword} = useUsersContext();
  const [currentPassword,setCurrentPassword] =useState("");
  const [newPassword,setNewPassword] =useState("");
  const [newPasswordAgain,setNewPasswordAgain] =useState("");

	
  const submitForm=(event)=>{
    event.preventDefault();
    if(newPassword===newPasswordAgain){
      changePassword(currentPassword,newPassword).then(()=>{
				NotificationManager.success('Şifre başarı ile değiştirildi.', 'Başarılı', 5000, null); 
        ClearForm();
      }).catch((err)=>{
				NotificationManager.error(err, 'Hata', 5000, null); 
        ClearForm();
      })
    }
    else{
      NotificationManager.error('Şifreler uyuşmuyor.', 'Hata', 5000, null); 
    }
  }

  const ClearForm=()=>{
    setCurrentPassword("");
    setNewPasswordAgain("");
    setNewPassword("");
  }

  return (
    <div className='cp'>
    <form onSubmit={submitForm}>
      <div className="change_password">
        <label htmlFor="">CHANGE PASSWORD</label>
      </div>
      <div className="chp__input-wrapper">
        <input type='password' className="chat__input" placeholder='Current Password' value={currentPassword} onChange={(e) => { setCurrentPassword(e.target.value); }} required />
      </div>
      <div className="chp__input-wrapper">
        <input type='password' className="chat__input" placeholder='New Password' value={newPassword} onChange={(e) => { setNewPassword(e.target.value); }} required />
      </div>
      <div className="chp__input-wrapper">
        <input type='password' className="chat__input" placeholder='New Password (Again)' value={newPasswordAgain} onChange={(e) => { setNewPasswordAgain(e.target.value); }} required />
      </div>
      <div className="cp__button-wrapper">
      <button className='cp_button' type='submit' >CHANGE PASSWORD</button>
      </div>
    </form>
    </div>
  )
}

export default ChangePassword;