import React, { useEffect, useRef, useState } from "react";
import "./styles/main.css";
import EmojiTray from "./components/EmojiTray";
import ChatInput from "./components/ChatInput";
import Header from "./components/Header";
// import ChatSidebar from "./components/ChatSidebar";
import Icon from "components/Icon";
// import Search from "./components/Search";
// import Profile from "./components/Profile";
import Convo from "./components/Convo";
import { useUsersContext } from "context/usersContext";
// import axios from "axios";
import MessageLoader from '../../components/MessageLoader'

const SupportChat = ({ match, history }) => {
	const { getSupportMessages, supportMessages, sendMessage, closeSupport, sendMedia } = useUsersContext();

	const supportId = match.params.id;

	const lastMsgRef = useRef(null);
	const [showAttach, setShowAttach] = useState(false);
	const [showEmojis, setShowEmojis] = useState(false);

	const [newMessage, setNewMessage] = useState("");


	useEffect(() => {
		getSupportMessages(supportId);
		// eslint-disable-next-line
	}, [supportId])


	const scrollToLastMsg = () => {
		if (supportMessages?.messages.length > 0) {
			lastMsgRef.current.scrollIntoView();
		}
	};

	const submitNewMessage = async () => {
		if (newMessage !== "") {
			sendMessage(supportId, newMessage);
			setNewMessage("");
			scrollToLastMsg();
		}
	};

	const submitNewMedia = async (data, file) => {
		console.log(data, file);
		if (file && data) {
			let media = { mimetype: file.type, filename: file.name, data: data.split(',')[1] }
			sendMedia(supportId, media);
			scrollToLastMsg();
		}
	}

	if (supportId) return (
		supportMessages?.support_id !== "" ?
			<div className="chat">
				<div className="chat__body">
					<div className="chat__bg"></div>

					<Header
						user={supportMessages}
						closeSupport={(selected_option) => closeSupport(supportId,selected_option)}
					/>
					<div className="chat__content">
						<Convo lastMsgRef={lastMsgRef} messages={supportMessages?.messages} />
					</div>
					<footer className="chat__footer">
						<button
							className="chat__scroll-btn"
							aria-label="scroll down"
							onClick={scrollToLastMsg}
						>
							<Icon id="downArrow" />
						</button>
						{!supportMessages?.is_closed ? <div>
							<EmojiTray
								showEmojis={showEmojis}
								newMessage={newMessage}
								setNewMessage={setNewMessage}
							/>
							<ChatInput
								showEmojis={showEmojis}
								setShowEmojis={setShowEmojis}
								showAttach={showAttach}
								setShowAttach={setShowAttach}
								newMessage={newMessage}
								setNewMessage={setNewMessage}
								submitNewMessage={submitNewMessage}
								submitNewMedia={submitNewMedia}
							/></div>:null}
					</footer>
				</div>
				{/* <ChatSidebar
				heading="Search Messages"
				active={showSearchSidebar}
				closeSidebar={() => setShowSearchSidebar(false)}
			>
				<Search />
			</ChatSidebar> */}

				{/* <ChatSidebar
				heading="Contact Info"
				active={showProfileSidebar}
				closeSidebar={() => setShowProfileSidebar(false)}
			>
				<Profile user={user} />
			</ChatSidebar> */}
			</div> : <MessageLoader></MessageLoader>
	)
	else return (null)
};

export default SupportChat;
