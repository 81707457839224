import React, { useState } from 'react'
import "./styles/main.css";
import { useAuthContext } from "context/authContext";
import axios from 'axios';
import * as apiurls from '../../api/apiurls'
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';


const Login = () => {
	const { Auth } = useAuthContext();
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
	const history = useHistory();

  const loginClick=()=>{
    axios.post(apiurls.LOGIN,{mail:mail,password:password}).then(data=>{
      Auth(data.data.results);
    }).catch(err=>{
      console.log(err);
        NotificationManager.error(err.response.data?.message, 'Hata', 5000, null); 
    })
  }


  return (
    <div className='login'>
      <div className="login__input-wrapper">
        <input type='text' className="chat__input" placeholder='Mail' onChange={(e) => { setMail(e.target.value); }} />
      </div>
      <div className="login__input-wrapper">
        <input type='password' className="chat__input" placeholder='Password' onChange={(e) => { setPassword(e.target.value); }} />
      </div>
      <div className="login__button-wrapper">
      <button className='login_button' onClick={loginClick} >Login</button>
      </div>
      <div className="register__button-wrapper">
        <a onClick={()=>{ history.push('/createbusiness');}}>Create Business</a>
        </div>
    </div>
  )
}

export default Login;