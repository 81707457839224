// var apiurl="https://beyes-what-chat.herokuapp.com"
// var apiurl="http://213.159.30.20:5000"
var apiurl=window.globalConfig.apiurl
// var apiurl="https://apiwhatchat.beyescloud.com"
export const SOCKET_URL=apiurl;
export const GET_PROFILE=apiurl+'/api/user/getprofile'
export const GET_SUPPORT_REQUESTS=apiurl+'/api/getSupportRequests'
export const GET_SUPPORT_CATEGORIES=apiurl+'/api/getSupportCategories'
export const GET_EMPLOYEES=apiurl+'/api/business/employees'
export const GET_SUPPORT_MESSAGES=apiurl+'/api/getSupportMessages'
export const CHANGE_PASSWORD=apiurl+'/api/user/changepassword'
export const BUSINESS_ADD_USER=apiurl+'/api/business/adduser'
export const BUSINESS_UPDATE_USER=apiurl+'/api/business/updateuser'
export const BUSINESS_DELETE_USER=apiurl+'/api/business/deleteuser'
export const BUSINESS_ADD_CATEGORY=apiurl+'/api/business/addcategory'
export const BUSINESS_UPDATE_CATEGORY=apiurl+'/api/business/updatecategory'
export const BUSINESS_DELETE_CATEGORY=apiurl+'/api/business/deletecategory'
export const CREATE_BUSINESS=apiurl+'/api/business/create'
export const LOGIN=apiurl+'/login'
export const SUPPORT_HISTORY=apiurl+'/api/business/supporthistory'
export const EMPLOYEE_STATS=apiurl+'/api/business/employeestats'
export const CREATE_API_KEY=apiurl+'/api/apikey/generate'