import React from "react";
import "./styles/main.css";

const MessageLoader = () => {

	return (
		<div className="home">
			<h1 className="home__title">Lütfen bekleyiniz.</h1>
		</div>
	);
};

export default MessageLoader;
