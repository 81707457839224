import { createContext, useContext, useEffect, useState } from "react";
import { useSocketContext } from "./socketContext";
import { useAuthContext } from "./authContext";
import axios from 'axios'
// import {NotificationManager} from 'react-notifications';
import * as apiurls from '../api/apiurls'

const UsersContext = createContext();

const useUsersContext = () => useContext(UsersContext);

const UsersProvider = ({ children }) => {
	const { socket, wpAccountStatus } = useSocketContext();
	const { token,authenticatedUser } = useAuthContext();
	const [users, setUsers] = useState([]);
	const [supportRequests,setSupportRequests]=useState([]);
	const [header, setHeader] = useState({ headers: { "Authorization": "Bearer " } });
	const [assignedSupportDetail, setAssignedSupportDetail] = useState([])
	const [supportMessages,setSupportMessages]=useState({support_id:"",messages:[],is_closed:true});
	const  [supportCategories,setSupportCategories]=useState([]);
	const  [employees,setEmployees]=useState([]);

	const getSupportRequests=(hdr)=>{
		axios.get(apiurls.GET_SUPPORT_REQUESTS, hdr).then(res => {
			setSupportRequests(res.data.results);
		});
	}

	const getSupportCategories=(hdr)=>{
		axios.get(apiurls.GET_SUPPORT_CATEGORIES, hdr).then(res => {
			setSupportCategories(res.data.results);
		});
	}

	const getEmployees=(hdr)=>{
		axios.get(apiurls.GET_EMPLOYEES, hdr).then(res => {
			setEmployees(res.data.results);
		});
	}
	

	useEffect(() => {
		if (token) {
			let h = { headers: { "Authorization": "Bearer " + token } }
			setHeader(h)
		}
		if (token && wpAccountStatus?.client_status) {
			let header = { headers: { "Authorization": "Bearer " + token } }
			getSupportRequests(header);
			getSupportCategories(header);
			getEmployees(header);
		}
		else {
			setUsers([]);
		}
	}, [token, wpAccountStatus])



	const _updateUserProp = (userId, prop, value) => {
		setUsers((users) => {
			const usersCopy = [...users];
			let userIndex = users.findIndex((user) => user.id === userId);
			const userObject = usersCopy[userIndex];
			usersCopy[userIndex] = { ...userObject, [prop]: value };
			return usersCopy;
		});
	};

	const getSupportMessages = async (support_id) => {
		return new Promise((resolve, reject) => {
			var body = { id: support_id };
			setSupportMessages({support_id:"",messages:[],is_closed:true});
			axios.post(apiurls.GET_SUPPORT_MESSAGES, body, header).then((response) => {
				setSupportMessages({support_id:support_id,...response.data.results});
				resolve(response.data.results);
			});
		});
	}


	const setMessageStatus = (data) => {
		setUsers((users) => {

			let userIndex = users.findIndex((user) => user.id === data.user_id);
			const usersCopy = JSON.parse(JSON.stringify(users));

			if (usersCopy[userIndex].last_message.message_id === data.message_id) {
				usersCopy[userIndex].last_message.status = data.status;
			}

			let uid = localStorage.getItem("selectedUserId");

			if (uid === data.user_id) {
				let msgIndex = usersCopy[userIndex].messages[data.message_date].findIndex(msg => msg.message_id === data.message_id);
				usersCopy[userIndex].messages[data.message_date][msgIndex].status = data.status;
			}
			return usersCopy;
		});
	}

	const newSupportRequest = (data) => {
		console.log("New Support Request", data);
		setSupportRequests((reqs)=>{
			let reqsCopy=JSON.parse(JSON.stringify(reqs));
			reqsCopy.push(data);
			return reqsCopy;
		})
	}

	const assignSupportRequest=(id)=>{
		socket.emit('assign_to_support_request', id)
		// if(!assignedSupportDetail){
		// }
		// else{
		// 	console.log('Zaten destek verdiğiniz başka biri var.');
		// 	NotificationManager.error('Zaten destek verdiğiniz başka biri var.', 'Hata', 5000, () => {
		// 		alert('callback');
		// 	});
		// }
		
	}

	const closeSupport=(support_id,selected_option)=>{
		socket.emit('close_support', { id: support_id, solved: true, status:selected_option });
		setSupportMessages(smsgs=>{
			if(support_id === smsgs.support_id){
				const copy = JSON.parse(JSON.stringify(smsgs));
				copy.is_closed=true;
				copy.solved=true;
				return copy;
			}
			else return smsgs;
		})

		setAssignedSupportDetail(asd=>{
			var asdCopy=JSON.parse(JSON.stringify(asd));
			let sIndex = asdCopy.findIndex((sd) => sd.id === support_id);
			asdCopy.splice(sIndex,1);
			return asdCopy;
		})
		// setAssignedSupportDetail(null);
	}

	// const fetchMessageResponse = async (data) => {
	// 	setUsers((users) => {
	// 		const { user_id, message } = data;

	// 		let userIndex = users.findIndex((user) => user.id === user_id);
	// 		const usersCopy = JSON.parse(JSON.stringify(users));
	// 		usersCopy[userIndex].last_message = message;

	// 		if (usersCopy[userIndex].messages["TODAY"] !== undefined) {
	// 			usersCopy[userIndex].messages["TODAY"].push(message);
	// 		}
	// 		else {
	// 			usersCopy[userIndex].messages["TODAY"] = [];
	// 			usersCopy[userIndex].messages["TODAY"].push(message);
	// 		}

	// 		let uid = localStorage.getItem("selectedUserId");
	// 		if (uid !== user_id && !message.from_me) {
	// 			usersCopy[userIndex].unread = usersCopy[userIndex].unread + 1;
	// 		}
	// 		else if (message.from_me) {
	// 			usersCopy[userIndex].unread = 0;
	// 		}
	// 		return usersCopy;
	// 	});
	// };

	const assignedSupport = (data) => {
		setAssignedSupportDetail(asd=>{
			let asdCopy = JSON.parse(JSON.stringify(asd));
			if(asdCopy.findIndex(a=>a.id===data.id)===-1){
				asdCopy.push(data);
			}
			return asdCopy;
		});
	}

	const answeredSupportRequest=(data)=>{
		setSupportRequests((reqs)=>{
			let reqsCopy=JSON.parse(JSON.stringify(reqs));
			reqsCopy.splice(reqsCopy.findIndex(item => item.id === data), 1)
			return reqsCopy;
		})
	}

	const fetchSupportMessage = (data) => {
		setSupportMessages(smsgs=>{
			if(data.support_id === smsgs.support_id){
				const copy = JSON.parse(JSON.stringify(smsgs));
				copy.messages.push(data.message);
				return copy;
			}
			else return smsgs;
		})

		setAssignedSupportDetail(asd=>{
			let asdCopy = JSON.parse(JSON.stringify(asd));
			let index= asdCopy.findIndex(item => item.id === data.support_id);
			asdCopy[index].unread=asdCopy[index].unread+1;
			return asdCopy;
		});
	}

	const changePassword=(currentpassword,newpassword)=>{
		return new Promise((resolve, reject) => {
			let body={
				currentpassword: currentpassword,
				newpassword:newpassword
			}
			axios.post(apiurls.CHANGE_PASSWORD, body, header).then(data=>{
				resolve(data);
			}).catch(err=>{
				reject(err.response.data.message);
			})
		});
	}


	const createApiKey=(password)=>{
		return new Promise((resolve, reject) => {
			let body={
				password:password
			}
			axios.post(apiurls.CREATE_API_KEY, body, header).then(data=>{
				resolve(data.data.results.api_key);
			}).catch(err=>{
				reject(err.response.data.message);
			})
		});
	}

	const createUser=(username,name,surname,mail,password,categories)=>{
		return new Promise((resolve, reject) => {
			let user={
				username:username,
				name:name,
				surname:surname,
				mail:mail,
				password:password,
				categories:categories
			}
			axios.post(apiurls.BUSINESS_ADD_USER, user, header).then(data=>{
				console.log(data.data.results);
				setEmployees(emps=>{
					const copy = JSON.parse(JSON.stringify(emps));
					copy.push(data.data.results);
					return copy;
				})
				resolve(data.data.results);
			}).catch(err=>{
				reject(err.response.data.message);
			})
		});
	}

	const updateUser=(id,username,name,surname,mail,categories)=>{
		return new Promise((resolve, reject) => {
			axios.post(apiurls.BUSINESS_UPDATE_USER,{user_id:id,mail:mail,username:username,name:name,surname:surname,categories:categories},header).then(data=>{
				setEmployees(emps=>{
					let employeesCopy=JSON.parse(JSON.stringify(emps));
					let index = employeesCopy.findIndex(e=>e.id===id);
					if(index!==-1){
						employeesCopy[index].mail=mail;
						employeesCopy[index].username=username;
						employeesCopy[index].name=name;
						employeesCopy[index].surname=surname;
					}
					return employeesCopy;
				});
				setSupportCategories(supcats=>{
					let upcatsCopy=JSON.parse(JSON.stringify(supcats));
					let map = upcatsCopy.map(cat=>{
						if(cat.employees.includes(id) && !categories.includes(cat.category_id)){
							cat.employees=cat.employees.filter(employee=>employee !== id);
						}
						else if (!cat.employees.includes(id) && categories.includes(cat.category_id)){
							cat.employees.push(id);
						}
					});
						return upcatsCopy;
				})
				resolve(data.data.results)
			}).catch(err=>{
				reject(err.response.data.message);
			});
		});
	}
	const deleteUser=(id)=>{
		return new Promise((resolve, reject) => {
			axios.post(apiurls.BUSINESS_DELETE_USER,{user_id:id},header).then(data=>{
				setEmployees(emps=>{
					let employeesCopy=JSON.parse(JSON.stringify(emps));
					employeesCopy = employeesCopy.filter(emp=>emp.id !== id);
					return employeesCopy;
				})
				resolve(data.data.results)
			}).catch(err=>{
				reject(err.response.data.message);
			});
		});
	}

	const addCategory=(name,employees,options_to_close_support)=>{
		return new Promise((resolve, reject) => {
			axios.post(apiurls.BUSINESS_ADD_CATEGORY,{name:name,employees:employees,options_to_close_support:options_to_close_support},header).then(data=>{
				setSupportCategories(categories=>{
					let categoriesCopy=JSON.parse(JSON.stringify(categories));
					categoriesCopy.push(data.data.results);
					return categoriesCopy;
				})
				resolve(data.data.results)
			}).catch(err=>{
				reject(err.response.data.message);
			});
		});
	}

	const updateCategory=(id, category,employees,options_to_close_support)=>{
		return new Promise((resolve, reject) => {
			axios.post(apiurls.BUSINESS_UPDATE_CATEGORY,{id:id,category:category,employees:employees,options_to_close_support:options_to_close_support},header).then(data=>{
				setSupportCategories(categories=>{
					let categoriesCopy=JSON.parse(JSON.stringify(categories));
					let index = categoriesCopy.findIndex(cat=>cat.category_id===id);
					if(index!==-1){
						categoriesCopy[index].category=category;
						categoriesCopy[index].employees=employees;
						categoriesCopy[index].options_to_close_support=options_to_close_support;
					}
					return categoriesCopy;
				})
				resolve(data.data.results)
			}).catch(err=>{
				reject(err.response.data.message);
			});
		});
	}

	const deleteCategory=(id)=>{
		return new Promise((resolve, reject) => {
			axios.post(apiurls.BUSINESS_DELETE_CATEGORY,{id:id},header).then(data=>{
				setSupportCategories(categories=>{
					let categoriesCopy=JSON.parse(JSON.stringify(categories));
					categoriesCopy = categoriesCopy.filter(cate=>cate.category_id!==id);
					return categoriesCopy;
				})
				resolve(data.data.results)
			}).catch(err=>{
				reject(err.response.data.message);
			});
		});
	}

	

	useEffect(() => {
		if (socket !== null) {
			// socket.on("fetch_response", fetchMessageResponse);
			socket.on("message_status", setMessageStatus);
			socket.on("new_support_request", newSupportRequest);
			socket.on("assigned_support", assignedSupport);
			socket.on("answered_support_request", answeredSupportRequest);
			socket.on("fetch_support_message", fetchSupportMessage);
		}
	}, [socket]);

	const setUserAsUnread = (userId) => {
		_updateUserProp(userId, "unread", 0);
	};

	const addNewMessage = (userId, message) => {
		let userIndex = users.findIndex((user) => user.id === userId);
		// axios.post('http://127.0.0.1:5000/api/sendMessage', { id: users[userIndex].chat_id, message: message },header);
		socket.emit('message', { id: users[userIndex].chat_id, message: message });
	};

	const addNewMedia = (userId, media) => {
		let userIndex = users.findIndex((user) => user.id === userId);
		// axios.post('http://127.0.0.1:5000/api/sendMedia', { id: users[userIndex].chat_id, ...media },header);
		socket.emit('media', { id: users[userIndex].chat_id, ...media });

	};

	const sendMessage=(support_id,message)=>{
		socket.emit('support_message', {is_media:false,support_id:support_id, message:message});
	}

	const sendMedia=(support_id,media)=>{
		socket.emit('support_message', {is_media:true,support_id:support_id, media:media});
	}

	return (
		<UsersContext.Provider value={{ header,token,authenticatedUser, users,supportRequests, assignedSupportDetail,supportMessages,supportCategories,employees, setUserAsUnread, addNewMessage, addNewMedia, getSupportMessages,sendMessage,sendMedia,closeSupport,assignSupportRequest,changePassword,createUser,addCategory,updateCategory,deleteCategory,createApiKey,updateUser,deleteUser }}>
			{children}
		</UsersContext.Provider>
	);
};

export { useUsersContext, UsersProvider };
