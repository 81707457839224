import React, { useState } from "react";
import "./styles/main.css";
// import avatar from "assets/images/profile-picture-girl-1.jpeg";
// import Icon from "components/Icon";
// import Alert from "./Alert";
import Contact from "./Contact";
import OptionsBtn from "components/OptionsButton";
import { useUsersContext } from "context/usersContext";
import { useAuthContext } from "context/authContext";
import { useHistory } from 'react-router-dom';
import Icon from "components/Icon";

const Sidebar = () => {
	const { LogOut, authenticatedUser } = useAuthContext();

	const history = useHistory();

	const doSomething = e => {
		switch (e) {
			case "Log out":
				LogOut();
				break;
			case "Change Password":
				history.push('/changepassword');
				break;
			case "Support History":
				history.push('/supporthistory');
				break;
			case "Categories":
				history.push('/categories');
				break;
			case "Users":
				history.push('/users');
				break;
			case "Create Api Key":
				history.push('/createapikey');
				break;
			case "Employee Stats":
				history.push('/employeestats');
				break;
			default:
				break;
		}
	}
	const { supportRequests, supportCategories, assignedSupportDetail } = useUsersContext();
	const [selectedCategory, setSelectedCategory] = useState("");


	return (
		<aside className="sidebar">
			<header className="header">
					<div className="sidebar__avatar-wrapper">
						{/* <img src={avatar} alt="Karen Okonkwo" className="avatar" /> */}
					</div>
				<label className="sidebar__user_info" >
					{authenticatedUser?.name} {authenticatedUser?.surname}
				</label>
				<div className="sidebar__actions">
					<OptionsBtn
						className="sidebar__action"
						ariaLabel="Menu"
						iconId="menu"
						iconClassName="sidebar__action-icon"
						onOptionSelect={doSomething}
						options={
							authenticatedUser?.is_business_admin ?
								[
									"Categories",
									"Users",
									"Support History",
									"Employee Stats",
									"Create Api Key",
									"Change Password",
									"Log out",
								] :
								[
									"Support History",
									"Change Password",
									"Log out",
								]
						}
					/>
				</div>
			</header>
			{/* <Alert /> */}
			<div className="sidebar__contacts">
				{
					supportCategories.map((category, i) => {
						var reqList=supportRequests.filter(req => req.category_name === category.category);
						return (
							<div key={i} >
								<div
									onClick={() => { setSelectedCategory(category.category_id) }}
								// className="sidebar-contact" 
								>
									<div className="sidebar-contact__content">
										<div className="sidebar-contact__top-content">
											<h2 className={selectedCategory === category.category_id ? "sidebar-contact__categoryname_selected" : "sidebar-contact__categoryname"}> {category.category} ({reqList.length})</h2>
										</div>
									</div>
								</div>
								{
									selectedCategory === category.category_id ? reqList.map((contact, index) => (
										<Contact key={index} contact={contact} assigned={false} />
									)) : null
								}
							</div>
						)
					})
				}
				<div >
					<div
						onClick={() => { setSelectedCategory("UnclosedSupports") }}
					// className="sidebar-contact" 
					>
						<div className="sidebar-contact__content">
							<div className="sidebar-contact__top-content">
								<h2 className={selectedCategory === "UnclosedSupports" ? "sidebar-contact__categoryname_selected" : "sidebar-contact__categoryname"}>Unclosed Supports ({assignedSupportDetail.length})</h2>
							</div>
						</div>
					</div>
					{
						selectedCategory === "UnclosedSupports" ? assignedSupportDetail.map((contact, index) => (
							<Contact key={index} contact={contact} assigned={true} />
						)) : null
					}
				</div>
			</div>
		</aside>
	);
};

export default Sidebar;
