import axios from "axios";
import { createContext, useContext,useState,useEffect } from "react";
import * as apiurls from '../api/apiurls'



const AuthContext = createContext();
const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    const [token,setToken]=useState(null);
    const [authenticatedUser ,setAuthenticatedUser]=useState(null);
    
    useEffect(() => {
      let t= localStorage.getItem('token');
      let exp=localStorage.getItem('token_expiration');
      if (t  && exp){
        let currentDate=new Date();
        let tokenExpDate=new Date(exp);
        if(currentDate < tokenExpDate){
            setToken(t);
            var dif = tokenExpDate.getTime() - currentDate.getTime();
            setTimeout(() => {
                setToken(null);
                localStorage.setItem('token',null);
                localStorage.setItem('token_expiration',null);
            }, dif);

            let h = { headers: { "Authorization": "Bearer " + t } }
            axios.get(apiurls.GET_PROFILE, h).then(res => {
                setAuthenticatedUser(res.data.results);
            });

        }
        else{
            localStorage.setItem('token',null);
            localStorage.setItem('token_expiration',null);
        }
      }
    }, [])

    const Auth=(data)=>{
        localStorage.setItem('token',data.token);
        let expdate=new Date();
        expdate.setSeconds(expdate.getSeconds()+data.token_expires_in_second)
        localStorage.setItem('token_expiration',expdate);
        setToken(data.token);
        setAuthenticatedUser(data);
    }

    const LogOut = ()=>{
        localStorage.setItem('token',null);
        localStorage.setItem('token_expiration',null);
        setToken(null)
    }
    


	return (
		<AuthContext.Provider value={{token,authenticatedUser, Auth,LogOut}}>{children}</AuthContext.Provider>
	);
};

export { useAuthContext, AuthProvider };
