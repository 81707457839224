import React, { useState } from 'react'
import "./styles/main.css";
import { NotificationManager } from 'react-notifications';
import { useUsersContext } from "context/usersContext";



const CreateApiKey = () => {
	const { createApiKey} = useUsersContext();
  const [pass,setPass] =useState("");
  const [apikey,setApikey]=useState("");

	
  const submitForm=(event)=>{
    event.preventDefault();
    createApiKey(pass).then((api_key)=>{
      setApikey(api_key);
      setPass("");
			NotificationManager.success('Api Key başarı ile oluşturuldu.', 'Başarılı', 5000, null); 
    }).catch((err)=>{
				NotificationManager.error(err, 'Hata', 5000, null); 
        setPass("");
    });
  }

  

  return (
    <div className='ak'>
    <form onSubmit={submitForm}>
      <div className="create_api_key">
        <label htmlFor="">CREATE API KEY</label>
      </div>
      <div className="ak__input-wrapper">
        <input type='password' className="chat__input" placeholder='Account Password' value={pass} onChange={(e) => { setPass(e.target.value); }} required />
      </div>
      <div className="ak__button-wrapper">
      <button className='ak_button' type='submit' >Create</button>
      </div>

      <div className='ak__warning-message-wrapper' > 
        <label className='ak_warning' >-If you create a new api key, the previous one will be invalid.</label>
        <label className='ak_warning' >-Don't forget to note the api key you created. You will not be able to view it again.</label>
        { apikey ? <label className='ak_api_key' >API KEY: {apikey}</label>:null}
      </div>
    </form>
    </div>
  )
}

export default CreateApiKey;