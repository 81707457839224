import React from "react";
import { Link } from "react-router-dom";
import { useUsersContext } from "context/usersContext";


const Contact = ({ contact,assigned }) => {
	const { assignSupportRequest} = useUsersContext();
	return (
		<Link
			className="sidebar-contact"
			to={`/supportchat/${contact.id}`}
			onClick={() => {
				if(!assigned) assignSupportRequest(contact.id);
				// setUserAsUnread(contact.id);
				// localStorage.setItem("selectedUserId",contact.id);
			}}
		>
			{/* <div className="sidebar-contact__avatar-wrapper">
				<img
					src={contact.profile_picture || defaultavatar}
					alt={contact.profile_picture || defaultavatar}
					className="avatar"
				/>
			</div> */}
			<div className="sidebar-contact__content">
				<div className="sidebar-contact__top-content">
					<h2 className="sidebar-contact__name"> {contact.user_name} - {contact.category_name} </h2>
				</div>
			</div>
		</Link>
	);
};

export default Contact;
