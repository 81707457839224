import React, { useState } from 'react'
import "./styles/main.css";
import { useUsersContext } from "context/usersContext";
import { NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';


const Categories = () => {
  const [editedCategory, setEditedCategory] = useState(null);
  const [input, setInput] = useState("");
  const [otcsInput, setOtcsInput] = useState("");
  const [options_to_close_support, setOptions_to_close_support] = useState([]);
  const [employee_list, setEmployee_list] = useState([]);
  const { supportCategories, addCategory, updateCategory, deleteCategory, employees } = useUsersContext();
  const [deletedCategoryId,setDeletedCategoryId]=useState(null);
  const deleteSupportCategory = (id) => {
    deleteCategory(id).then(() => {
      NotificationManager.success('Kategori başarı ile silindi.', 'Başarılı', 5000, null);
    }).catch((err) => {
      NotificationManager.error(err, 'Hata', 5000, null);
    });
    setDeletedCategoryId(null);
  }

  const editCategory = (category) => {
    console.log(category);
    setEditedCategory(category);
    setInput(category.category);
    setOtcsInput("");
    setOptions_to_close_support(category.options_to_close_support);
    setEmployee_list(category.employees);
  }

  const cancelEditCategory = () => {
    setEditedCategory(null);
    clearForm();
  }

  const addOTCS = () => {
    setOptions_to_close_support((value) => {
      let v = JSON.parse(JSON.stringify(value));
      if (!v.includes(otcsInput) && otcsInput !== "") {
        v.push(otcsInput);
      }
      return v;
    })
    setOtcsInput("");
  }

  const deleteOTCS = (item) => {
    console.log(item);
    setOptions_to_close_support((value) => {
      let v = JSON.parse(JSON.stringify(value));
      return v.filter(otcs_item => otcs_item !== item);
    })
  }

  const selectEmployeeOnChange = (emp_id) => {
    setEmployee_list(list => {
      let l = JSON.parse(JSON.stringify(list));
      if (l.includes(emp_id)) {
        return l.filter(item => item !== emp_id);
      }
      else {
        l.push(emp_id);
        return l;
      }
    })

  }

  const clearForm = () => {
    setInput("");
    setOptions_to_close_support([]);
    setOtcsInput([]);
    setEmployee_list([]);
  }

  const submit = () => {
    if (input !== "") {
      if (!editedCategory) {
        addCategory(input, employee_list, options_to_close_support).then(() => {
          NotificationManager.success('Kategori başarı ile eklendi.', 'Başarılı', 5000, null);
          clearForm();
        }).catch((err) => {
          NotificationManager.error(err, 'Hata', 5000, null);
          clearForm();
        })
      }
      else {
        updateCategory(editedCategory.category_id, input, employee_list, options_to_close_support).then(() => {
          NotificationManager.success('Kategori başarı ile düzenlendi.', 'Başarılı', 5000, null);
          cancelEditCategory();
        }).catch((err) => {
          NotificationManager.error(err, 'Hata', 5000, null);
          cancelEditCategory();
        })
      }
    }
  }

  return (
    <div >
      <div className='categories-categorylist' >
        <div className='categories'>
          <div className='table_panel' >
            <table className='categories_list'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Delete</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {
                  supportCategories?.map((category, index) =>
                    <tr key={index}>
                      <td><label className="categories_category_name" >{category.category}</label></td>
                      <td><button onClick={() => { setDeletedCategoryId(category.category_id) }} className='categories_delete_button' >Delete</button></td>
                      <td><button onClick={() => { editCategory(category) }} className='categories_edit_button' >Edit</button></td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='categories-addcategory'>
        <div className='categories'>
          <div className="categories_title">
            <label>{editedCategory ? "EDİT CATEGORY" : "ADD CATEGORY"}</label>
          </div>
          <div className="categories__input-wrapper">
            <input type='text' className="chat__input" value={input} onChange={(e) => { setInput(e.target.value) }} placeholder='Category Name' />
          </div>

          <div className='categories__add__option-wrapper' >
            <input type='text' className="chat__input" placeholder='Option To Close Support' value={otcsInput} onChange={(e) => { setOtcsInput(e.target.value) }} />
            <button className='categories__add__option__button' onClick={addOTCS} >
              {/* <Icon id="add" /> */}
              +
            </button>
          </div>

          <div className='categories__added__option__list-wrapper' >
            {
              options_to_close_support?.map(otcs =>
                <div className='categories__added__option' key={otcs} >
                  <label>{otcs}</label>
                  <button className='categories__added__option__cancel__btn' onClick={() => { deleteOTCS(otcs) }} >X</button>
                </div>)
            }
          </div>

          <div className='categories__employee__list-wrapper' >
            <ul role="listbox" className='categories__listbox'>
              {employees?.map(emp =>
                <li key={emp.id} >
                  <div>
                    <input type="checkbox" checked={employee_list.includes(emp.id)} onChange={() => { selectEmployeeOnChange(emp.id); }} />
                    {emp.name} {emp.surname} ({emp.username})
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div className="categories__button-wrapper">
            <button className='categories_button' onClick={submit} >{editedCategory ? "UPDATE" : "ADD"}</button>
            {editedCategory ? <button className='categories_button' onClick={cancelEditCategory} >CANCEL</button> : null}
          </div>
        </div>
      </div>
      <SweetAlert
          show={deletedCategoryId!==null}
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => { deleteSupportCategory(deletedCategoryId); }}
          onCancel={() => { setDeletedCategoryId(null); }}
          focusCancelBtn
        >
        </SweetAlert>
    </div>
  )
}

export default Categories;