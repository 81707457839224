import React, { useState } from 'react'
import "./styles/main.css";
import { useUsersContext } from "context/usersContext";
import { NotificationManager } from 'react-notifications';
import Multiselect from 'multiselect-react-dropdown';
import SweetAlert from 'react-bootstrap-sweetalert';


const Users = () => {
  const { employees, createUser, updateUser, deleteUser, supportCategories } = useUsersContext();
  const [updatedUser, setUpdatedUser] = useState(null);
  const multiselectref = React.useRef();
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [assignedCategories, setAssignedCategories] = useState([]);
  const [deletedUserId,setDeletedUserId] = useState(null);

  const submitForm = (event) => {
    event.preventDefault();
    if (!updatedUser) {
      let categories = assignedCategories.map(ac => ac.category_id);
      createUser(username, name, surname, mail, password, categories).then(() => {
        NotificationManager.success('Kullanıcı başarı ile oluşturuldu.', 'Başarılı', 5000, null);
        clearForm();
      }).catch((err) => {
        NotificationManager.error(err, 'Hata', 5000, null);
      })
    } else {
      let categories = assignedCategories.map(ac => ac.category_id);
      updateUser(updatedUser.id, username, name, surname, mail, categories).then(() => {
        NotificationManager.success('Kullanıcı başarı ile güncellendi.', 'Başarılı', 5000, null);
        cancelEditUser();
      }).catch((err) => {
        NotificationManager.error(err, 'Hata', 5000, null);
      })
    }
  }

  const clearForm = () => {
    setUsername("");
    setName("");
    setSurname("");
    setMail("");
    setPassword("");
    setAssignedCategories([]);
    multiselectref.current.resetSelectedValues();
  }

  const cancelEditUser = () => {
    setUpdatedUser(null);
    clearForm();
  }

  const deleteUserById = (id) => {
    deleteUser(id).then(() => {
      NotificationManager.success('Kullanıcı başarı ile silindi.', 'Başarılı', 5000, null);
    }).catch((err) => {
      NotificationManager.error(err, 'Hata', 5000, null);
    });
    setDeletedUserId(null);
  }

  const editUser = (user) => {
    setUsername(user.username);
    setMail(user.mail);
    setSurname(user.surname);
    setName(user.name);
    setUpdatedUser(user);
    setAssignedCategories(supportCategories.filter(c => c.employees.includes(user.id)));
  }

  return (
    <div >
      <div className='users-userlist' >
        <div className='users'>
          <div className='table_panel'>
            <table className='users_list'>
              <thead>
                <tr>
                  <th>Name Surname</th>
                  <th>Delete</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {
                  employees?.map((user, index) =>
                    <tr key={index}>
                      <td><label className="users_user_name" >{user.name} {user.surname}</label></td>
                      <td><button onClick={() => { setDeletedUserId(user.id) }} className='users_delete_button' >Delete</button></td>
                      <td><button onClick={() => { editUser(user) }} className='users_edit_button' >Edit</button></td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='users-adduser'>
        <div className='users'>
          <form onSubmit={submitForm} autoComplete="new-password"  >
            <div className="user_info">
              <label>{updatedUser ? "EDİT USER" : "CREATE USER"}</label>
            </div>
            <div className="cu__input-wrapper">
              <input type='text' className="chat__input" placeholder='Username' required value={username} onChange={(e) => { setUsername(e.target.value); }} />
            </div>
            <div className="cu__input-wrapper">
              <input type='text' className="chat__input" placeholder='Name' required value={name} onChange={(e) => { setName(e.target.value); }} />
            </div>
            <div className="cu__input-wrapper">
              <input type='text' className="chat__input" placeholder='Surname' required value={surname} onChange={(e) => { setSurname(e.target.value); }} />
            </div>
            <div className="cu__input-wrapper">
              <input type='email' id='ml' autoComplete="new-password" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="chat__input" placeholder='Mail Address' required value={mail} onChange={(e) => { setMail(e.target.value); }} />
            </div>
            {!updatedUser ? <div className="cu__input-wrapper">
              <input type='password' id='pw' autoComplete="new-password" className="chat__input" placeholder='Password' required value={password} onChange={(e) => { setPassword(e.target.value); }} />
            </div> : null}
            <div className='' >
              <Multiselect
                ref={multiselectref}
                displayValue="category"
                isObject={true}
                onRemove={(list, value) => { setAssignedCategories(list); }}
                onSelect={(list, value) => { setAssignedCategories(list); }}
                options={supportCategories}
                selectedValues={assignedCategories}
                placeholder="Categories"
                closeIcon='cancel'
                style={{
                  inputField: {
                    color: 'white'
                  },
                  searchBox: {
                    background: 'rgb(51, 56, 59)',
                    outline: 'none',
                    border: 'none',
                    'border-radius': '10px',
                    height: 'auto',
                    width: '330px',
                    'margin-top': '7px',
                    'margin-left': '10px',
                    'min-height': '36px'
                  },
                  optionContainer: {
                    background: 'rgb(50, 55, 57)',
                  },
                  multiselectContainer: {
                    color: 'white'
                  },
                  chips: {
                    background: '#056162'
                  },
                }}
              />
            </div>
            <div className="cu__button-wrapper">
              <button className='cu_button' type='submit' >{updatedUser ? "UPDATE" : "CREATE USER ACCOUNT"}</button>
              {updatedUser ? <button className='cu_button' onClick={cancelEditUser} >CANCEL</button> : null}
            </div>
          </form>
        </div>
      </div>
      <SweetAlert
          show={deletedUserId!==null}
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => { deleteUserById(deletedUserId); }}
          onCancel={() => { setDeletedUserId(null); }}
          focusCancelBtn
        >
        </SweetAlert>
    </div>
  )
}

export default Users;