import Icon from "components/Icon";
import React from "react";
import formatTime from "utils/formatTime";

const Convo = ({ lastMsgRef, messages: allMessages }) => {


	return (
		<div className="chat__msg-group">
			{allMessages.map((message, msgIndex) => {
				const assignRef = () =>
					msgIndex === allMessages.length - 1
						? lastMsgRef
						: undefined;
				return (
					<>
						{(message.has_media && message.type === 'image') ? (
							<div
								className={`chat__msg chat__img-wrapper ${message.sender ? "chat__msg--rxd" : "chat__msg--sent"
									}`}
								ref={assignRef()}
							>
								<img src={"data:" + message.media_data?.mimetype + ";base64, " + message.media_data?.data} alt="" className="chat__img" />
								<span className="chat__msg-footer">
									<span>{formatTime(message.timestamp)}</span>
									{!message.sender && (
										<Icon
											id={
												message?.status === 1
													? "singleTick"
													: "doubleTick"
											}
											aria-label={message?.status}
											className={`chat__msg-status-icon ${message?.status === 3
												? "chat__msg-status-icon--blue"
												: ""
												}`}
										/>
									)}
								</span>

								<button
									aria-label="Message options"
									className="chat__msg-options"
								>
									<Icon id="downArrow" className="chat__msg-options-icon" />
								</button>
							</div>
						) : (message.has_media && message.type === 'video') ?
							(
								<div
									className={`chat__msg chat__img-wrapper ${message.sender ? "chat__msg--rxd" : "chat__msg--sent"
										}`}
									ref={assignRef()}
								>
									<video src={"data:" + message.media_data?.mimetype + ";base64, " + message.media_data?.data} alt="" controls={true} className="chat__img" />
									<span className="chat__msg-footer">
										<span>{formatTime(message.timestamp)}</span>
										{!message.sender && (
											<Icon
												id={
													message?.status === 1
														? "singleTick"
														: "doubleTick"
												}
												aria-label={message?.status}
												className={`chat__msg-status-icon ${message?.status === 3
													? "chat__msg-status-icon--blue"
													: ""
													}`}
											/>
										)}
									</span>

									<button
										aria-label="Message options"
										className="chat__msg-options"
									>
										<Icon id="downArrow" className="chat__msg-options-icon" />
									</button>
								</div>
							) : (message.has_media && (message.type === 'ptt' || message.type === 'audio')) ?
								(
									<div className={`chat__msg ${message.sender ? "chat__msg--rxd" : "chat__msg--sent"
										}`} ref={assignRef()}>

										<audio controls >
											<source src={"data:" + message.media_data?.mimetype + ";base64, " + message.media_data?.data} type={message.media_data?.mimetype.split(';')[0]} />
										</audio>

										<span className="chat__msg-filler"> </span>
										<span className="chat__msg-footer">
											{formatTime(message.timestamp)}
										</span>
										<button
											aria-label="Message options"
											className="chat__msg-options"
										>
											<Icon id="downArrow" className="chat__msg-options-icon" />
										</button>
									</div>
								)
								: message.sender ? (
									<p className="chat__msg chat__msg--rxd" ref={assignRef()}>
										<span>{message.content}</span>
										<span className="chat__msg-filler"> </span>
										<span className="chat__msg-footer">
											{formatTime(message.timestamp)}
										</span>
										<button
											aria-label="Message options"
											className="chat__msg-options"
										>
											<Icon id="downArrow" className="chat__msg-options-icon" />
										</button>
									</p>
								) : (
									<p className="chat__msg chat__msg--sent" ref={assignRef()}>
										<span>{message.content}</span>
										<span className="chat__msg-filler"> </span>
										<span className="chat__msg-footer">
											<span> {formatTime(message.timestamp)} </span>
											<Icon
												id={
													message?.status === 1
														? "singleTick"
														: "doubleTick"
												}
												aria-label={message?.status}
												className={`chat__msg-status-icon ${message?.status === 3
													? "chat__msg-status-icon--blue"
													: ""
													}`}
											/>
										</span>
										<button
											aria-label="Message options"
											className="chat__msg-options"
										>
											<Icon id="downArrow" className="chat__msg-options-icon" />
										</button>
									</p>
								)}
					</>
				);
			})}
		</div>
	);
};

export default Convo;
