import React from 'react'
import "./styles/main.css";
import { useSocketContext } from "context/socketContext";
import { useAuthContext } from "context/authContext";
import { QRCodeSVG } from 'qrcode.react';


const AuthWhatsApp = () => {
    const { wpAccountStatus } = useSocketContext();
    const { LogOut } = useAuthContext();

    const Log_Out=()=>{
        LogOut();
    }

    return (
        <div className='authwp'>
            <div className='authwp__text'>{wpAccountStatus.message}</div>
            {wpAccountStatus.qrcode ? <div className="authwp__qr-wrapper">
                <QRCodeSVG size={325} value={wpAccountStatus.qrcode} />
            </div> : null}
            <div className='authwp__logout' onClick={Log_Out} >Log Out</div>
        </div>
    )
}

export default AuthWhatsApp;